<template>
  <div @click.prevent="showDetail" class="product">
    <div class="item-content">
        <div class="product-info">
            <img :src="hasPhoto ? photoPrincipale : logo" alt="avatar">
            <div class="user-meta-info">
                <p class="product-name">{{ name }}</p>
                <p class="product-category">{{ categorie }}</p>
            </div>
        </div>
        <div class="product-price">
            <p class="product-category-addr"><span>Prix: </span>{{ price }}Fcfa</p>
        </div>
        <div class="product-rating">
            <p class="product-rating-inner"><span>Note: </span>
                <a class="d-flex align-center">
                    <!-- 5 <img src="assets/img/star.png" class="avatar-xxs ml-2" alt="star"> -->
                </a>
            </p>
        </div>
        <!-- <div class="product-stock-status">
            <p class="product-stock-status-inner">
                <small class="badge badge-success">In Stock</small>
            </p>
        </div> -->
        <!-- <div class="action-btn">
            <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact"></i>
            <i class="lar la-trash-alt text-danger font-20 mr-2"></i>
        </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const logo = require('../../assets/img/logo.png')
export default {
    props: {
        product: {type: Object, required: true}
    },
    data(){
        return {
            logo
        }
    },
    methods: {
                
        showDetail(){
            this.$router.push({name: 'market-product', params: {uid: this.product.uid}})
        }
    },
    computed: {
        ...mapGetters({
            categories: 'market/categories'
        }),
        hasProduct(){return this.product !== null && this.product !== undefined },
        name(){
            return this.hasProduct ? this.product.libelle: '-'
        },
        categorie(){
            if(!this.hasProduct) return null
            let c = this.categories.find(item => item.uid === this.product.categorie)
            if(c) return c.libelle
            return null
        },
        price(){
            return this.hasProduct ? this.product.actualAmount : null
        },
        hasPhoto(){
            if(this.hasProduct){
                let photos = JSON.parse(this.product.photos)
                return photos.length > 0
            }
            return false
        },
        photoPrincipale(){
            if(this.hasProduct){
                let photos = JSON.parse(this.product.photos)
                return photos[0].url
            }
            return null
        }
    }

}
</script>

<style lang="scss" scoped>
.product{
    font-size: 14px;
    padding: 10px 20px; 
    cursor: pointer;
    .item-content{
        .product-info{
            text-align: center;
            justify-content: center;
            img {
                 max-width: 200px;
                 height: 200px;
                 margin-bottom: 20px;
            }
            .user-meta-info{
                text-align: center;
                border-top: solid 1px rgba(0,0,0,0.1);
                padding: 15px 0px;
                .product-name{
                    padding: 10px;
                    height: 50px;
                    font-size: 14px;
                    font-weight: 700;
                }
                .product-category{
                    padding: 10px;
                    background-color: chocolate;
                    color: white;
                }
             }
            
        }
    }
}
</style>