<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />

    <!-- <div class="layout-px-spacing">                
        <div class="row layout-spacing layout-top-spacing" id="cancel-row">
            <div class="col-lg-12">
                <div class="">
                    <div class="widget-content searchable-container grid">
                        <div class="card-box">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-7 filtered-list-search align-self-center">
                                    <form class="form-inline my-2 my-lg-0">
                                        <div class="">
                                            <i class="las la-search toggle-search"></i>
                                            <input type="text" id="input-search" class="form-control search-form-control  ml-lg-auto" placeholder="Search Products">
                                        </div>
                                    </form>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-5 text-sm-right text-center align-self-center">
                                    <div class="d-flex justify-content-sm-end contact-options">
                                        <router-link :to="{name: 'market-add-product'}" href="javascript:void(0)" class="pointer font-25 s-o mr-2 bs-tooltip"> 
                                            <i class="las la-plus-circle"></i> 
                                        </router-link>
                                        <a href="javascript:void(0);" title="List View" class="pointer font-25 view-list s-o mr-2 bs-tooltip">
                                            <i class="las la-list"></i>
                                        </a>
                                        <a title="Grid View" class="pointer font-25 view-grid active-view s-o mr-2 bs-tooltip">
                                            <i class="las la-th-large"></i>
                                        </a>
                                        <a title="Filter" class="pointer font-25 s-o bs-tooltip mr-2">
                                            <i class="las la-filter"></i>
                                        </a>
                                        <select class="btn btn-outline-primary btn-sm h-auto p-2" id="exampleFormControlSelect1">
                                            <option>Select Sort By</option>
                                            <option>Name</option>
                                            <option>Price Low to High</option>
                                            <option>Price Hight to Low</option>
                                            <option>Stock</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="searchable-items grid card-box">
                            <div class="items items-header-section" style="border: solid 1px;">
                                <div class="item-content">
                                    <div class="">
                                        <h4>Full Name</h4>
                                    </div>
                                    <div class="product-price">
                                        <h4>Price</h4>
                                    </div>
                                    <div class="product-rating">
                                        <h4 style="margin-left: 0;">Rating</h4>
                                    </div>
                                    <div class="product-stock-status">
                                        <h4 style="margin-left: 3px;">Status</h4>
                                    </div>
                                    <div class="product-stock-status">
                                        <h4 style="margin-left: 3px;">Options</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 items"  v-for="(p, index) in products" :key="index">
                                    <product :product="p" :index="index"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="layout-px-spacing">
        <div class="row layout-spacing layout-top-spacing">
            <div class="col-lg-12 col-md-12">
                <div class="">
                    <div class="widget-content searchable-container grid">
                        <div class="card-box">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-7 filtered-list-search align-self-center">
                                    <form class="form-inline my-2 my-lg-0">
                                        <div class="">
                                            <i class="las la-search toggle-search"></i>
                                            <input type="text" id="input-search" class="form-control search-form-control  ml-lg-auto" placeholder="Search Products">
                                        </div>
                                    </form>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-5 text-sm-right text-center align-self-center">
                                    <div class="d-flex justify-content-sm-end contact-options">
                                        <router-link :to="{name: 'market-add-product'}" href="javascript:void(0)" class="pointer font-25 s-o mr-2 bs-tooltip"> 
                                            <i class="las la-plus-circle"></i> 
                                        </router-link>
                                        <a href="javascript:void(0);" title="List View" class="pointer font-25 view-list s-o mr-2 bs-tooltip">
                                            <i class="las la-list"></i>
                                        </a>
                                        <a title="Grid View" class="pointer font-25 view-grid active-view s-o mr-2 bs-tooltip">
                                            <i class="las la-th-large"></i>
                                        </a>
                                        <a title="Filter" class="pointer font-25 s-o bs-tooltip mr-2">
                                            <i class="las la-filter"></i>
                                        </a>
                                        <select class="btn btn-sm">
                                            <option>Select Sort By</option>
                                            <option>Name</option>
                                            <option>Price Low to High</option>
                                            <option>Price Hight to Low</option>
                                            <option>Stock</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-box">
                        <div class="row">
                            <div class="col-3 market-filter">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="">
                                            <label for="type" class="d-block">Type de produit</label>
                                            <select class="" v-model="type" id="type" name="type">
                                                <option value="all">Tout</option>
                                                <option v-for="(t, index) in types" :value="t.uid" :key="index">{{ t.libelle }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-12" style="padding-top: 20px">
                                        <label for="categorie">Catégorie</label>
                                    </div>
                                    <div class="col-12 filter-categorie" v-for="(c, index) in filtredCategories" :key="index">
                                        <i class="las la-angle-right"></i> <a href="javascript:void(0)" @click.prevent="categorie = c.uid"> {{ c.libelle }}</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12" style="padding: 20px">
                                        <label for="tags">Tags</label>
                                    </div>
                                    <div class="col-12" v-for="(t, index) in filterdTags" :key="index">
                                        <a href="javascript:void(0)"  @click.prevent="tag = t.uid"> {{ t.libelle }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-4" style="padding: 5px; border-radius: 15px;" v-for="(p, index) in products" :key="index">
                                        <product :product="p" class="shadow" style="border-radius: 10px;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row market-box">
        <div class="col-3 market-filter">
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <label for="type">Type de produit</label>
                        <select class="form-control" v-model="type" v-select="{placeholder: 'Select type of product'}" id="type" name="type">
                            <option value=""></option>
                            <option v-for="(t, index) in types" :value="t.uid" :key="index">{{ t.libelle }}</option>
                        </select>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>Catégories</h6>
                </div>
                <div class="col-12" v-for="(c, index) in filtredCategories" :key="index">
                    <a href="javascript:void(0)" @click.prevent="categorie = c.uid"> {{ c.libelle }}</a>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>Tags</h6>
                </div>
                <div class="col-12" v-for="(t, index) in filterdTags" :key="index">
                    <a href="javascript:void(0)"  @click.prevent="tag = t.uid"> {{ t.libelle }}</a>
                </div>
            </div>
        </div>
        <div class="col-9 market-content">
            <div class="row">
                <div class="col-9">
                    <div class="search-bar">
                        <input type="text" class="form-control search-form-control" placeholder="Recherche">
                    </div>
                </div>
                <div class="col-3">
                    <div class="row">
                        <div class="col-9">
                           <select  class="form-control">
                            <option value=""></option>
                                <option v-for="(o, index) in orders" :key="index" :value="o.value">{{ o.libelle }}</option>
                           </select>
                        </div>
                        <div class="col-3">
                            <a href="javascript:void(0)"><i class="las la-sort-amount-down"></i></a>
                            <a href="javascript:void(0)"><i class="las la-sort-amount-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12"></div>
                    <div class="col-4" v-for="(p, index) in products" :key="index">
                        <product :product="p" />
                    </div>
            </div>
            <div class="add_product">
                <router-link :to="{name: 'market-add-product'}" href="javascript:void(0)"><i class="icofont icofont-plus"></i></router-link>
            </div>
        </div>
    </div> -->
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import Navbar from '../../components/navbar.vue'
import Product from '../../components/market/product.vue'
import Select from '../../directive/select'
import { LOAD_PRODUCTS } from '../../graphql/market'
import('../../assets/css/apps/ecommerce.css')
export default {
    directive: {Select},
    components: { Navbar, Product },
    data(){
        return {
            navbarItems: [
              {
                libelle: 'Market Place'
              }
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Market Place',
            pageDescription: 'Espace de vente des produits sur CASEAC',
            type: null, 
            categorie: null,
            tag: null,
            filtredCategories: [],
            filterdTags: [],
            loadProduct: true,
            hasError: false,
            error_msg: null,
            products: []
        }
    },
    mounted(){
        this.loadProduct =  false
    },
    watch: {
        type: {
            handler(){
                if(this.type === 'all') {
                    this.filtredCategories = this.categories
                    this.filterdTags = this.filtres
                }else{
                    this.filtredCategories = [...this.categories].filter(item => item.type === this.type)
                    this.filterdTags = [...this.filtres].filter(item => item.type === this.type)
                    this.loadProduct = false
                }
                
            },
            deep: true
        },
        categorie(){
            this.loadProduct = false
        },
        tag(){
            this.loadProduct = false
        }
    },
    apollo: {
        loadProduts: {
            query: LOAD_PRODUCTS,
            skip () {
                return this.loadProduct
            },
            variables(){
                return {
                    "type": this.type,
                    "categorie": this.categorie,
                    "tags": this.tag
                }
            },
            error (error) {
                this.error_msg = JSON.stringify(error.message)
                this.loadProduct = true
            },
            update ({marketProducts}) {
                console.log(marketProducts)
                this.products =  marketProducts
                this.setProducts(marketProducts)
                this.loadProduct = true
            }
        }
    },
    methods: {
        ...mapMutations({
            setProducts: 'market/SET_PRODUCTS'
        })
    },  
    computed: {
        ...mapGetters({
            types: 'market/typeProducts',
            categories: 'market/categories',
            filtres: 'market/filtres'
        }),
        orders(){
            return [
                {libelle: 'Nom', value: 'name'},
                {libelle: 'Prix', value: 'price'}
            ]
        }
    }

}
</script>

<style lang="scss" scoped>
.market-filter{
    border-right: solid 1px rgba(0,0,0,0.1);
    padding: 20px 10px;
    .filter-categorie{
        padding: 5px 20px;
    }
}
.market-box{
    margin: 10px;
    border: solid 1px rgba(0,0,0,0.1);
    border-radius: 0.5rem;
    
    .market-content{
        padding: 20px 10px;
        .search-form-control{
            width: 100%;
            font-size: 14px;
            background-color: #e6f0ff;
            border: none;
            border-radius: 30px;
            color: #2262c6;
            padding: 0px 4px 0px 40px;
            height: 36px;
            font-weight: 500;

        }
        .add_product{
            position: absolute;
            bottom: 5px;
            right: 5px;
        }
    }
}
</style>